import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/prop-box/prop-box-main.jpg" imageAlt="An image of the prop box" mdxType="TextMediaSplit">
      <h1>{`Graphical Prop Box`}</h1>
      <p>{`The COVID-19 lock-down saw a huge increase in British people wanting to explore their genealogy and heritage. Many people want to look into their history but find it hard to have a personal connection with what they find. This project focusses on encouraging people to become involved and participate with stories from people of the past and continue the conversation within their own families and heritage.`}</p>
      <p>{`For this project box I drew inspiration from my own family history - the murder of Sarah Rose in 1918. The outcome being a graphical prop box that emulates a keepsake box of Sarah’s life. Each item was thoroughly researched to be made authentic to the time-period, region, techniques and culture of the time.  The idea being that viewers can interact with each item and feel truly connected to Sarah and her story.`}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/prop-box/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      